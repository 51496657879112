.mb {
    margin-bottom: 4px !important;
}

.mb-xs {
    margin-bottom: 8px !important;
}

.mb-xs2 {
    margin-bottom: 12px !important;
}

.mb-s {
    margin-bottom: 16px !important;
}

.mb-sm {
    margin-bottom: 20px !important;
}

.mb-m {
    margin-bottom: 24px !important;
}

.mb-l {
    margin-bottom: 32px !important;
}

.mb-l2 {
    margin-bottom: 40px !important;
}

.mb-xl {
    margin-bottom: 56px !important;
}

.mb-xl2 {
    margin-bottom: 48px !important;
}

.mb-xxl {
    margin-bottom: 64px !important;
}

.mb-xxxl {
    margin-bottom: 72px !important;
}

.mb-exl {
    margin-bottom: 80px !important;
}

.mb-exxl {
    margin-bottom: 96px !important;
}

.my-s {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
}

.my-m {
    margin-top: 16px;
    margin-bottom: 32px;
}

.mt-a {
    margin-top: auto;
}

.colorBrand {
    color: var(--brand-primary) !important;
}

.colorFasting {
    color: var(--logo-color-fast) !important;
}

.colorError {
    color: var(--error-color) !important;
}

.colorSuccess {
    color: var(--primary-default) !important;
}

.colorPromo {
    color: var(--promotion-color) !important;
}

.colorTextSecondary {
    color: var(--text-secondary) !important;
}

.centered {
    text-align: center !important;
}

.rightCentered {
    text-align: end !important;
}

.lineThrough {
    text-decoration: line-through;
}

.underline {
    text-decoration: underline;
}

.capitalize {
    text-transform: capitalize;
}

.colorMainSecondary {
    color: var(--text-main-secondary) !important;
}
