.default {
    color: var(--default-text-color);
}

.white {
    color: var(--white-color);
}

.centered {
    text-align: center;
}

.colorFast {
    color: var(--logo-color-fast);
}

.h1 {
    font-weight: 900;
    font-size: 42px;
    line-height: 46px;
}

.h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
}

.h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
}

.h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}

.h4New {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.h6 {
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
}

.h1New {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
}
