.header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--background-color);
}

.headerContainer {
    position: relative;
    padding: 15px var(--default-indent) 0;
    min-height: 62px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.floatHeader .headerContainer,
.rowElements .headerContainer {
    flex-direction: row;
    align-items: center;
}

.floatHeader {
    position: fixed;
    transition: 0.3s;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 8px rgb(0 0 0 / 8%);
}

.float_header_with_price {
    position: fixed;
    transition: 0.3s;
    width: 100%;
    z-index: 1000;
}

.float_header_with_price .header {
    top: 0;
}

.floatHeader .header {
    position: relative;
}

.hideHeader {
    top: -160px;
    transition: 0.2s;
}

.showHeader {
    top: 0;
}

.price_line {
    background: var(--promotion-color);
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 56px;
    width: 100%;
    color: var(--white-color);
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pageEnd {
    color: #454e57;
}

.progressBox {
    margin-top: 8px;
    position: relative;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: rgb(212 218 224 / 50%);
}

.iconBack {
    cursor: pointer;
}

.fastEasy .headerContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: unset;
}

.skipButton {
    min-width: 107px;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--default-text-color);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.skipButton svg {
    margin-left: 6px;
}
