.default {
    font-weight: 500;
    color: var(--default-text-color);
}

.medium {
    font-size: 16px;
    line-height: 24px;
}

.extraSmall {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.mediumSmall {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.mediumSmall2 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.small {
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
}

.caption {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

.small2 {
    font-size: 16px;
    line-height: 24px;
}

.bold {
    font-weight: 700;
}

.normal {
    font-weight: 400;
}

.grey {
    color: var(--text-secondary) !important;
}

.white {
    color: var(--white-color);
}

.darkGrey {
    color: var(--help-icon-background);
}

.centered {
    text-align: center;
}

.transparence {
    opacity: 0.8;
}

.colorFast {
    color: var(--logo-color-fast);
}

.withGreyBg {
    background-color: var(--modal-text-background);
    padding: 12px 8px;
    border-radius: 12px;
}

.uppercase {
    text-transform: uppercase;
}

.letterSpacing1 {
    letter-spacing: 1px;
}

.safeLight {
    color: var(--safeLight);
}

.underlineChild a {
    text-decoration: underline;
}

.red {
    color: var(--promotion-color);
}

.downloadPageColor {
    color: #454e57;
}

/* ToDo: experiment 24.09 */
.FC_exp_overline {
    font-size: 10px;
    line-height: 12px;
}

/* ToDo: experiment 24.09 */
.FC_exp_caption_small {
    font-size: 11px;
    line-height: 16px;
}
