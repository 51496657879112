:root {
    --white-color: #fff;
    --black-color: #000;
    --text-main: #252d48;
    --primary-default: #f09f8f;
    --brand-primary-male: #ff964f;
    --text-secondary: #8696a6;
    --stroke-color: #d4dae0;
    --highlights: #fbfefb;
    --highlights-error: #fffafa;
    --error-color: #f16b6b;
    --success-color: #f09f8f;
    --promotion-color: #ff4f64;
    --button-paypal-color: #ffc439;
    --default-unit-input: #a6b3e4;
    --cookie-background: #f2fcf4;
    --default-indent: 16px;
    --default-indent-double: calc(var(--default-indent) * 2);
    --logo-color-fast: #252d48;
    --brand-primary-female-fast: #f09f8f;
    --background-color-fast: #f2efee;
    --text-secondary-fast: #9296a3;
    --safeLight: #1975ff;
    --grey-ligt: #c4c4c4;
    --text-main-secondary: #292e33;
    --background-subdude: #f2f2f2;
    --border-default: #d6d7db;
    --border-subdued: #e9eaed;
    --brand-primary: #f09f8f;
    --brand-primary-disabled: #efbcaf;
    --background-color: #fff;
    --footer-background-color: #f9f9fa;
    --option-border-color: #d4dae0;
    --option-background-color: #fff;
    --default-text-color: #454e57;
    --hint-color: #454e57;
    --label-color: #8696a6;
    --hint-bg: #f9fafb;
    --brand-primary-opacity-5: rgb(73 212 97 / 5%);
    --logo-color: #454e57;
    --box-border-color: #d4dae0;
    --hint-background: #f2fcf4;
    --multioption-check-background: #d4dae0;
    --fasting-option-background: #f3f5f7;
    --help-icon-background: #8696a6;
    --modal-payment-background: #28292b;
    --modal-text-background: #f9f9fa;
    --experiment-purpleLight: #f6f6fd;
    --critical-highlight: #fef5f5;
    --guarantee-block: #f0f5fe;
    --result-background: #f9f9fa;
    --decorative-male: #92c3ec;
    --surface-default: #e5e5e5;
    --facebook-blue: #1877f2;
    --facebook-hover-blue: #005eba;
    --facebook-hover-white: #f2efee;

    /*lib colors*/
    --lib-secondary-default: #252d48;
    --lib-surface-secondary: #f2efee;
    --lib-surface-default: #f2efee;
    --lib-success-surface: #f5faf7;
    --lib-warning-surface: #fefbe7;
    --lib-critical-surface: #fcf3f3;
    --lib-surface-main: #fff;
    --lib-text-secondary: #767a86;
    --lib-text-secondary-subdued: #767a86;
    --lib-primary-hovered: #ee8f7d;
    --lib-warning-highlight: #fbf4b6;
    --lib-warning-default: #f7ec7c;
    --lib-primary-active: #ec8774;
    --lib-surface-hovered: #e9e4e2;
    --lib-primary-default: #f09f8f;
    --lib-border-default: #d6d7db;
    --lib-text-main: #252d48;
    --lib-success-highlight: #cbe2d7;
    --lib-decorative-safe: #b7d4ec;
    --lib-decorative-safe-surface: #d4ceea;
    --lib-decorative-rating: #ddcdd0;
    --lib-primary-disabled: #f5bdb3;
    --lib-success-default: #7fb89d;
    --lib-decorative-discount: #64b990;
    --lib-border-subdued: #e9eaed;
}
