html {
    font-family: roboto, sans-serif;
    font-size: 0;
    background-color: var(--background-color);
}

body,
html,
#app {
    min-height: 100%;
}

#app {
    position: relative;
    font-size: 16px;
}

.ReactModal__Body--open {
    overflow: hidden;
}
